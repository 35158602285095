export const convertToDate = (date) => {
	console.log("in the function", date);
	let d = date.split("/");
	if (d[0] >= 1 && d[0] <= 9) {
		var month = `0${d[0]}`;
	} else {
		var month = d[0];
	}
	if (d[1] >= 1 && d[1] <= 9) {
		var day = `0${d[1]}`;
	} else {
		var day = d[1];
	}
	let dat = d[2] + "/" + month + "/" + day;
	return dat;
};

export const getDaysListInMonthStart = function (month, year) {
	let daysInMonth = new Date(year, month, 0).getDate();
	return Array.from({ length: daysInMonth }, (v, k) => k + 1);
};
export const getDaysListInMonthEnd = function (month, year) {
	let daysInMonth = new Date(year, month, 0).getDate();
	return Array.from({ length: daysInMonth }, (v, k) => k + 1);
};
export const generateYear = (year, addone, yearlength) => {
	console.log(year, addone);
	let years = +year + +addone;
	console.log(+year + +addone);
	let generateYear = Array.from({ length: +yearlength }, (v, k) => k + years);
	return generateYear;
};

export const dateLabelFetcher = async (dateType) => {
	if (dateType === "endDate") {
		// var endday = document.getElementById("endday");
		// var valueendday = endday.options[endday.selectedIndex].value;

		var endmonth = document.getElementById("endmonth");
		var valueendmonth = endmonth.options[endmonth.selectedIndex].value;

		var endyear = document.getElementById("endyear");
		var valueendyear = endyear.options[endyear.selectedIndex].value;

		return await dateFormatter(valueendmonth, valueendyear);
		// valueendday
	} else if (dateType === "startDate") {
		// var startday = document.getElementById("startday");
		// var valuestartday = startday.options[startday.selectedIndex].value;

		var startmonth = document.getElementById("startmonth");
		var valuestartmonth = startmonth.options[startmonth.selectedIndex].value;

		var startyear = document.getElementById("startyear");
		var valuestartyear = startyear.options[startyear.selectedIndex].value;

		return await dateFormatter(valuestartmonth, valuestartyear);
		// valuestartday
	} else {
		return "rerro";
	}
};
const dateFormatter = (month, year) => {
	if (month >= 1 && month <= 9) {
		var monthFormat = `0${month}`;
	} else {
		var monthFormat = month;
	}
	// if (day >= 1 && day <= 9) {
	// 	var dayFormat = `0${day}`;
	// } else {
	// 	var dayFormat = day;
	// }
	let dat = year + "-" + monthFormat + "-" + "15";
	// dayFormat;

	return dat;
};

export const getDynamicYears = async (age) => {
	console.log(age);
	let month = (new Date().getMonth() + 4) % 12;
  if (month === 0) {
    month = 12;
  }
	let currentYear = new Date().getFullYear();
	//let month = currentmonth.getMonth();
	let endYear = [
		{
			label: `${month}/${currentYear + 10}`,
			item: properDater(`${month}/${currentYear + 10}`),
		},
		{
			label: `${month}/${currentYear + 20}`,
			item: properDater(`${month}/${currentYear + 20}`),
		},
		{
			label: `${month}/${currentYear + 25}`,
			item: properDater(`${month}/${currentYear + 25}`),
		},
		{
			label: `${month}/${currentYear + 30}`,
			item: properDater(`${month}/${currentYear + 30}`),
		},
		//`${month}/${currentYear + 40}`,
	];
	switch (+age) {
		case 25:
			return await endYear;
		case 36:
			// var years = endYear.splice(-1, 1);
			console.log("dfs", endYear, years);
			return await endYear;
		case 49:
			//var years = endYear.splice(-2, 2);
			console.log(endYear, years);
			return await endYear;
		case 62:
			var years = endYear.splice(-2, 2);
			console.log(endYear, years);
			return await endYear;
		case 75:
			var years = endYear.splice(-3, 3);
			console.log(endYear, years);
			return await endYear;
		default:
			return [];
	}
};

const properDater = (date) => {
	let [month, year] = date.split("/");
	month = month.padStart(2, "0");
	let dateObject = new Date(parseInt(year), parseInt(month) - 1, 1);
	return dateObject;
};

export const getDynamicYearsdump = (date, age) => {
	console.log(age, date);
	let currentmonth = new Date(date);
	let currentYear = new Date(date).getFullYear();
	let month = currentmonth.getMonth();
	let endYear = [
		`${month}/${currentYear + 10}`,
		currentYear + 20,
		currentYear + 25,
		currentYear + 30,
		currentYear + 40,
	];
	switch (age) {
		case 20:
			return endYear;
		case 38:
			var years = endYear.splice(-1, 1);
			console.log(endYear, years);
			return endYear;
		case 50:
			var years = endYear.splice(-2, 2);
			console.log(endYear, years);
			return endYear;
		case 75:
			var years = endYear.splice(-4, 4);
			console.log(endYear, years);
			return endYear;
		default:
			break;
	}
};
