export const SERVERDev =
	"http://of-smarterpayouts-beanstalk-env-dev.eba-gjm2hg9i.us-east-2.elasticbeanstalk.com/";
export const SERVER = "https://api.smarterpayouts.com/";
export const RawServer =
	"http://of-smarterpayouts-beanstalk-env-prod.eba-b9ud7at8.us-east-2.elasticbeanstalk.com/";
export const age = [
	19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
	38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
	57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
];
export const WEIGHT = [
	{ label: "Select from Options", value: "Average Weight" },
	//{ label: "55-75", value: 65 },
	{ label: "76-100", value: 80 },
	{ label: "101-125", value: 110 },
	{ label: "126-150", value: 130 },
	{ label: "151-175", value: 160 },
	{ label: "176-200", value: 180 },
	{ label: "201-225", value: 210 },
	{ label: "226-250", value: 240 },
	{ label: "251-275", value: 260 },
	{ label: "276-300", value: 290 },
];
export const AGE_MALE_Female = [
	{ label: "18-30", value: 20 },
	{ label: "31-49", value: 38 },
	{ label: "50-69", value: 50 },
	{ label: "70 +", value: 75 },
	{
		label: "Prefer To Put Manually",
		value: "Prefer To Put Manually",
	},
];

export const Height_Ranges = [
	{ label: "4'0''- 5'2''", value: "4.5" },
	{ label: "5'3''- 6'2''", value: "5.8" },
	// { label: "6''3-7''0'", value: "6.5" },
	// { label: "7'' +", value: "7.2" },
	{
		label: "Prefer To Put Manually",
		value: "Prefer To Put Manually",
	},
];
export const GENDER = ["Male", "Female", "Prefer Not to Answer"];
export const height_Menual = [
	{ label: "Select from Range", value: 3.9 },
	{ label: "4'0", value: 4.0 },
	{ label: "4'1", value: 4.1 },
	{ label: "4'2", value: 4.2 },
	{ label: "4'3", value: 4.3 },
	{ label: "4'4", value: 4.4 },
	{ label: "4'5", value: 4.5 },
	{ label: "4'6", value: 4.6 },
	{ label: "4'7", value: 4.7 },
	{ label: "4'8", value: 4.8 },
	{ label: "4'9", value: 4.9 },
	{ label: "5'0", value: 5.0 },
	{ label: "5'1", value: 5.1 },
	{ label: "5'2", value: 5.2 },
	{ label: "5'3", value: 5.3 },
	{ label: "5'4", value: 5.4 },
	{ label: "5'5", value: 5.5 },
	{ label: "5'6", value: 5.6 },
	{ label: "5'7", value: 5.7 },
	{ label: "5'8", value: 5.8 },
	{ label: "5'9", value: 5.9 },
	{ label: "6'0", value: 6.0 },
	{ label: "6'1", value: 6.1 },
	{ label: "6'2", value: 6.2 },
	{ label: "6'3", value: 6.3 },
	{ label: "6'4", value: 6.4 },
	{ label: "6'5", value: 6.5 },
	{ label: "6'6", value: 6.6 },
	{ label: "6'7", value: 6.7 },
	{ label: "6'8", value: 6.8 },
	{ label: "6'9", value: 6.9 },
	{ label: "7'0", value: 7.0 },
	{ label: "7'1", value: 7.1 },
	{ label: "7'2", value: 7.2 },
	{ label: "7'3", value: 7.3 },
	{ label: "7'4", value: 7.4 },
	{ label: "7'5", value: 7.5 },
	{ label: "7'6", value: 7.6 },
	{ label: "7'7", value: 7.7 },
];
export const Health_Status = ["Great", "Normal", "Fair", "Below Fair"];
export const Yes_No_prefer = [
	{
		Attributes: "Yes",
		Impact: 0.5,
	},
	{
		Attributes: "No",
		Impact: 0,
	},
	{
		Attributes: "Prefer Not to Answer",
		Impact: 0,
	},
];

export const Yes_No = ["Yes", "No"];
export const Physical_Exercise = [
	{
		AttributesName: "No Exercise",
		Impact: 0,
	},
	{
		AttributesName: "Once a week",
		Impact: 0.015,
	},
	{
		AttributesName: "2 times a week",
		Impact: 0.02,
	},
	{
		AttributesName: "5 (or more) times a week",
		Impact: 0.04,
	},
	{
		AttributesName: "Prefer Not to Answer",
		Impact: 0,
	},
];
export const BP = [
	{
		Attributes: "Normal",
		Impact: 0,
	},
	{
		Attributes: "Medicated",
		Impact: 0.005,
	},
	{
		Attributes: "High",
		Impact: 0.08,
	},
	{
		Attributes: "Not Sure",
		Impact: 0.04,
	},
	{
		Attributes: "Prefer Not to Answer",
		Impact: 0,
	},
];

export const infraction = [
	{
		AttributesName: "0 Driving Infraction",
		Impact: 0,
	},
	{
		AttributesName: "1 infractions in a year",
		Impact: 0.05,
	},
	{
		AttributesName: "2 infractions in a year",
		Impact: 0.12,
	},
	{
		AttributesName: "3 infractions in a year",
		Impact: 0.3,
	},
	// {
	// 	AttributesName: "4 infractions in a year",
	// 	Impact: 0.65,
	// },
	// {
	// 	AttributesName: "5 infractions in a year",
	// 	Impact: 1,
	// },
	{
		AttributesName: "Prefer Not to Answer",
		Impact: 0,
	},
];
export const payment_Frequency = [
	{
		Frequency: "Monthly",
		Value: 12,
	},
	{
		Frequency: "Quarterly",
		Value: 4,
	},
	{
		Frequency: "Semiannually",
		Value: 2,
	},
	{
		Frequency: "Annually",
		Value: 1,
	},
	{
		Frequency: "Weekly",
		Value: 52,
	},
];

export const weight_Value = [
	// {
	// 	AttributesName: "Ideal Weight ",
	// 	Impact: -0.15,
	// },
	{
		AttributesName: "Underweight",
		Impact: 0.2,
	},
	{
		AttributesName: "Average Weight",
		Impact: 0,
	},
	{
		AttributesName: "Overweight",
		Impact: 0.15,
	},
	{
		AttributesName: "Obese",
		Impact: 0.25,
	},
	{
		AttributesName: "Prefer To Put Manually",
	},
];

export const credit_rating_master = [
	// {
	// 	companyName: "AEGON / Transamerica",
	// 	Rating: "Rating A",
	// },
	{
		companyName: "AIG American General",
		Rating: "Rating A",
	},
	// {
	// 	companyName: "AIG American General (Wilmington Branch)",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "Aurora",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "AXA Equitable",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "Berkshire Hathaway ",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "Canada Life",
	// 	Rating: "Rating A",
	// },
	{
		companyName: "North American Life ",
		Rating: "Rating A",
	},
	// {
	// 	companyName: "GABC Insurance",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "Genworth  Life Insurance",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "The Hartford  Life Insurance Company",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "John Hancock ",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "Lincoln Financial",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "Liberty Mutual Life ",
	// 	Rating: "Rating A",
	// },
	{
		companyName: "MetLife Insurance Company",
		Rating: "Rating A",
	},
	{
		companyName: "New York Life Insurance Company",
		Rating: "Rating A",
	},
	{
		companyName: "Prudential Life ",
		Rating: "Rating A",
	},
	// {
	// 	companyName: "Symetra Life ",
	// 	Rating: "Rating A",
	// },
	// {
	// 	companyName: "State Farm",
	// 	Rating: "Rating A",
	// },
	{
		companyName: "Others",
		Rating: "Rating B",
	},
];
export const DESCRIPTION_TEXT = {
	Age: "Age needed to determine family protection dollar amount.",
	Gender: "Gender needed to determine family protection amount duration.",
	InsuranceCompany:
		"Insurance company name is needed to determine the overall payout.",
	PaymentType:
		"Payment Type provides correct payout for life contingent or guaranteed payments. Call your insurance company if not sure.",
	Duration:
		"Duration is the way payments are received. Either Monthly , Quarterly, Semi-annually and Annually.",
	Increase:
		"Increase mean how much your payments goes up in value each year i.e. 3% , 2% or other ",
	ApproxHeight:
		"Approx. Height is needed to determine the cost associated to provide the family protection",
	ApproxWeight:
		"Approx. weight is needed to determine the cost associated to provide the family protection",
	Smoke:
		"Do you smoke  is needed to determine the cost associated to provide the family protection",
	HealthProfile:
		"Health Profile is needed to determine the cost associated to provide the family protection",
	DL: "Driving License suspension is needed to determine the cost associated to provide the family protection",
	BP: "Blood pressure is needed to determine the health status of our client.",
	FP: "Family protection is a life insurance benefit that is shared between your loved ones and the funding facility",
};

export const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
