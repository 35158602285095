import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { GetInTouch } from "../GetInTouch/GetInTouch";
import "./TermsAndCondition.css";

/**
 * @author
 * @function TermsAndCondition
 **/

export const TermsAndCondition = (props) => {
	return (
    <div className="">
      <Container fluid>
        <Row className="my-4">
          <Col className="text-center">
            <Card className="main-header-bg">
              <Card.Body>
                <h3 className="text-white">
                  Terms and Conditions for Smarter Payouts
                </h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="mx-lg-5 mx-sm-1">
          <Row>
            <Col>
              <p className="letter-sp-text">
                These Terms and Conditions ("Agreement") govern your use of the
                services provided by Smart Settlement Inc, a structured
                settlement company. By using our services, you agree to be bound
                by the terms and conditions outlined in this Agreement. Please
                read these terms carefully.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text">1. Services:</h5>
              <p className="letter-sp-text">
                Smart Settlement Inc specializes in structured settlement
                services, which may involve collecting personal information,
                including your phone number, and communicating via text message.
                The quotes and illustrations provided on our website are for
                illustrative purposes only and are subject to change without
                notice.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> 2. User Responsibilities</h5>
              <p className="letter-sp-text">
                <br />
                <h6>You agree to:</h6>
                (a) Provide accurate and complete information when using our
                services. <br />
                (b) Understand that the quotes provided on our website are
                illustrative and subject to change.
                <br />
                (c) Keep your login credentials and personal information secure.
                <br />
                (d) Comply with all applicable laws and regulations when using
                our services.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Privacy Policy Review:</h5>
              <p className="letter-sp-text">
                Your use of our services is also governed by our Privacy Policy,
                which outlines how we collect, use, and protect personal
                information. Please review our{" "}
                <a
                  //  style={{ textDecoration: "none" }}
                  className="text-tag-detail"
                  href="/POLICIES"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                for detailed information.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Communication:</h5>
              <p className="letter-sp-text">
                By using our services, you consent to receiving communication
                from Smart Settlement Inc. We may use your phone number and
                email to contact you.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Intellectual Property:</h5>
              <p className="letter-sp-text">
                All content and materials on our platform, including but not
                limited to text, graphics, logos, and software, are protected by
                intellectual property laws. You may not use, reproduce, or
                distribute these materials without our explicit permission.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text">Termination:</h5>
              <p className="letter-sp-text">
                We reserve the right to terminate or suspend your access to our
                services for any reason, without notice. You may also terminate
                your use of our services at any time.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Disclaimer:</h5>
              <p className="letter-sp-text">
                Smart Settlement Inc provides its services "as-is" and makes no
                warranties or guarantees, express or implied, including but not
                limited to warranties of merchantability or fitness for a
                particular purpose. We do not make any promises about the
                financial outcome of structured settlements.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Limitation of Liability:</h5>
              <p className="letter-sp-text">
                To the extent permitted by law, Smart Settlement Inc will not be
                liable for any direct, indirect, incidental, special, or
                consequential damages, including but not limited to loss of
                profits, data, or financial opportunities.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text">
                {" "}
                Changes to Terms and Conditions:
              </h5>
              <p className="letter-sp-text">
                We reserve the right to update these terms and conditions at any
                time. Any changes will be effective upon posting on our website.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Governing Law:</h5>
              <p className="letter-sp-text">
                This Agreement is governed by and construed in accordance with
                the laws of the state or jurisdiction in which Smart Settlement
                Inc is located, without giving effect to any principles of
                conflicts of law.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Contact Information:</h5>
              <p className="letter-sp-text">
                If you have any questions or concerns about these terms and
                conditions or wish to contact us, please email us at
                info@smartersettlement.com. By using our services, you agree to
                be bound by these terms and conditions. If you do not agree with
                any part of this Agreement, please do not use our services.
              </p>
            </Col>
          </Row>
        </div>
        <GetInTouch />
      </Container>
    </div>
  );
};
