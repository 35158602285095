const aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 10);
aYearFromNow.setMonth(aYearFromNow.getMonth() + 3);
const startDate = new Date();
startDate.setMonth(startDate.getMonth() + 3);

export const initialValues = {
	Age: "",
	PaymentType: 2,
	PaymentAmount: "",
	AnnualIncrease: 0,
	PaymentStartDate: startDate,
	PaymentEndDate: aYearFromNow,
	PaymentMode: "Monthly",
};
