import React, { Component, useCallback } from "react";
import { Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import { SponserList } from "../newDesign/SponserList/SponserList";
import { WhySmarter } from "../newDesign/WhySmarter/WhySmarter";
import { AboutUs } from "../newDesign/AboutUs/AboutUs";
import { ClientReviews } from "../newDesign/ClientReviews/ClientReviews";
import { GetInTouch } from "../newDesign/GetInTouch/GetInTouch";
import "./LandingPage.css";
import { CalculatorForm } from "../newDesign/CalculatorForm/CalculatorForm";
import { CompanyDetail } from "../newDesign/GetInTouch/CompanyDetail/CompanyDetail";
import OurServices from "../newDesign/OurServices/OurServices";
import { Timer } from "../newDesign/Timer";
const overlay = require("../newDesign/assets/img/logo.png");

/**
 * @author
 * @class LandingPage
 **/

function LandingPage() {
  const [loader, setLoader] = useState(true);
  const [locker, setlocker] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <Container fluid className="landing-page-background">
      <LoadingScreen
        loading={loader}
        bgColor="#f1f1f1"
        spinnerColor="#54d000"
        textColor="#676767"
        logoSrc={overlay}
        text="Get Your Money, The Smart Way"
      >
        <Row>
          <SponserList />
          <WhySmarter />
          <CalculatorForm />
          <OurServices />
          <AboutUs />
          <ClientReviews />
          <GetInTouch />
          <CompanyDetail />
        </Row>
      </LoadingScreen>
    </Container>
  );
}
//}

export default LandingPage;
