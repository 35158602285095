import React from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import "./ClientCount.css";

/**
 * @author
 * @function ClientCount
 **/

export const ClientCount = (props) => {
	return (
		<Container className='mt-3 calculator-layer-smarter' fluid>
			<Row className='jsutify-content-center text-center mb-1'>
				<Col>
					<h2 className='maintag-calculator fw-bolder'>Calculated Offer</h2>
					<p className='tag-line-calculator'>No wait, No Drama, No Salesman</p>
				</Col>
			</Row>
			<Row className='justify-content-center'>
				<Col lg={6} sm={12} className='mx-auto mb-4 '>
					<Card className='shadow'>
						<Card.Body>
							<h6 className='general-color'>Offers Accepted</h6>
							<h2 className='general-color'>1250+</h2>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={6} sm={12} className='text-center'>
					<Card className='shadow '>
						<Card.Body>
							<h6 className='general-color'>Offers Provided</h6>
							<h2 className='general-color'>1300+</h2>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{/* <Row>
				

				</Col>
					<Card className=' border-0'>
						<Card.Body className='text-center'>
							<h3 className='tag-color mt-lg-3 mt-xh-1'>Our Legend</h3>
							<div className='provided-offers-div'>
								<div className='w-50 text-center'>
									<p className='offer-provided-text'>Offers Provided</p>
									<h2 className='provided-offers-count'>1300+</h2>
								</div>
								<div className='w-50 text-center'>
									<p className='offer-accepted-text'>Offers Accepted</p>
									<h2 className='accepted-offers-count'>1200+</h2>
								</div>
								<div className='w-50 text-center'></div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row> */}
			<br />
			<br />
		</Container>
	);
};
