import * as yup from "yup";
export const schema = yup.object().shape({
  Gender: yup.string().required("Select your Gender"),
  Height: yup.string().required("Select your Approx. Height"),
  Weight: yup.string().required("Select your Approx. Weight"),
  Smoke: yup.string().required("Select an Option"),
  Health: yup.string().required("Select an Option"),
  TrafficVoilation: yup.string().required("Select an Option"),
  CardiaticHealth: yup.string().required("Select an Option"),
});
