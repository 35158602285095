import React from "react";
import {
	Button,
	Form,
	InputGroup,
	Row,
	Col,
	Container,
	Card,
} from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faComment,
	faEnvelope,
	faMessage,
	faPaperPlane,
	faPhone,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./GetInTouch.css";
import { CompanyDetail } from "./CompanyDetail/CompanyDetail";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { SERVER } from "../../../constant/AppConstants";

/**
 * @author
 * @function GetInTouch
 **/

const schema = yup.object().shape({
	Name: yup.string().required("Name is required"),
	Contact: yup.string(),
	Email: yup.string().email().required("Email is required"),
	Message: yup.string().required("Message is required"),
});

export const GetInTouch = (props) => {
	const submit = async (values) => {
		let response = await axios.post(`${SERVER}api/contact/feedBack`, values);
		if (response.status == 200) {
			toast(
				"Your Message as been sent!  you will hear from us soon. Thank you! ",
				{
					icon: "👏",
					style: {
						borderRadius: "10px",
						background: "#54d000",
						color: "#fff",
					},
				}
			);
		} else {
			toast.error("Internal Server Error!");
		}
	};
	return (
    <Container fluid className="mt-5 mb-3 ">
      <Toaster position="bottom-center" reverseOrder={false} />
      <Row>
        <Col sm={12}>
          <div className="text-center">
            <h2 className="getIn-text-main">Contact Us</h2>
            <p
              style={{
                textAlign: "center",
                letterSpacing: "1px",
                fontSize: "18px",
                fontWeight: "500",
              }}
              className="matched-offer-text "
            >
              We'll get back to you within 24 hours.{" "}
            </p>
            {/* <Button variant='warning' className='getIn-touch-company-detail'>
							See Company Details
						</Button> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={5} md={12}>
          <Card className="border-0">
            <Card.Body className="px-5 text-center ">
              <img
                style={{ width: "80%", height: "80%" }}
                alt="logo"
                src={require("../assets/img/clientOfficer.png")}
              ></img>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={7} md={12}>
          <Card className="border-0">
            <Card.Body>
              <Formik
                autoComplete="off"
                validationSchema={schema}
                onSubmit={async (values, { resetForm }) => {
                  await submit(values);
                  resetForm();
                }}
                initialValues={{
                  Name: "",
                  Contact: "",
                  Email: "",
                  Message: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  resetForm,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="12"
                        className="mb-3"
                        controlId="validationFormikUsername"
                      >
                        <Form.Label className="label-weight-getIn-Touch">
                          Name
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text
                            className="getInTouch-userName shadow  "
                            id="inputGroupPrepend"
                          >
                            <FontAwesomeIcon
                              icon={faUser}
                              size="lg"
                              style={{ color: "gray" }}
                            />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Your Name"
                            aria-describedby="inputGroupPrepend"
                            name="Name"
                            value={values.Name}
                            onChange={handleChange}
                            isInvalid={!!errors.Name}
                            className="getInTouch-userName shadowName "
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.Name}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormikUsername"
                      >
                        <Form.Label className="label-weight-getIn-Touch">
                          Phone
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text
                            className="getInTouch-userName shadow  "
                            id="inputGroupPrepend"
                          >
                            <FontAwesomeIcon
                              icon={faPhone}
                              size="lg"
                              style={{ color: "gray" }}
                            />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Your Phone Number"
                            aria-describedby="inputGroupPrepend"
                            name="Contact"
                            value={values.Contact}
                            onChange={handleChange}
                            isInvalid={!!errors.Contact}
                            className="getInTouch-userName shadowName "
                          />
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="12"
                        className="mb-3"
                        controlId="validationFormikUsername"
                      >
                        <Form.Label className="label-weight-getIn-Touch">
                          Email
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text
                            className="getInTouch-userName shadow  "
                            id="inputGroupPrepend"
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              size="lg"
                              style={{ color: "gray" }}
                            />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="youremail@example.com"
                            aria-describedby="inputGroupPrepend"
                            name="Email"
                            value={values.Email}
                            onChange={handleChange}
                            isInvalid={touched.Email && !!errors.Email}
                            onBlur={handleBlur}
                            className="getInTouch-userName shadowName "
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.Email}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormikUsername"
                      >
                        <Form.Label className="label-weight-getIn-Touch">
                          Message
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text
                            className="getInTouch-userName shadow  "
                            id="inputGroupPrepend"
                          >
                            <FontAwesomeIcon
                              icon={faComment}
                              size="lg"
                              style={{ color: "gray" }}
                            />
                          </InputGroup.Text>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="Message"
                            value={values.Message}
                            onChange={handleChange}
                            isInvalid={touched.Message && !!errors.Message}
                            onBlur={handleBlur}
                            className="getInTouch-userName shadowName "
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.Message}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Row>

                    <Row className="text-center mt-3">
                      <Col lg={12}>
                        <Button type="submit" className="send-message-button">
                          {" "}
                          <FontAwesomeIcon
                            icon={faPaperPlane}
                            size="lg"
                            style={{ color: "white", marginRight: "12px" }}
                          />
                          <span className="ml-5">Send Message</span>
                        </Button>{" "}
                        <br />
                        <br />
                        <a
                          style={{ color: "gray" }}
                          href="/Disclaimer"
                          target="_blank"
                        >
                          Disclaimers
                        </a>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>{" "}
    </Container>
  );
};
