import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, createContext, useRef, useEffect } from "react";
import {
	Container,
	Button,
	Card,
	ProgressBar,
	Col,
	Row,
} from "react-bootstrap";
import { StepOne } from "./StepOne/StepOne";
import { StepTwo } from "./StepTwo/StepTwo";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import "./CalculatorForm.css";
import { MultiStepForm, Step } from "react-multi-form";
import Slider from "react-slick";
import { settings } from "./FormConstants";
import { ClientCount } from "../ClientCount/ClientCount";
import { StepThree } from "./StepThree/StepThree";
import { StepFour } from "./StepFour/StepFour";

export const FormContext = createContext();

/**
 * @author
 * @function CalculatorForm
 **/

export const CalculatorForm = (props) => {
  const [active, setActive] = useState(1);
  const [formData, setFormData] = useState({});
  const [LumpsumformData, setLumpsumFormData] = useState({});
  const [LCPForm, setLCPForm] = useState(true);
  const [totalSteps, settotalSteps] = useState(2);
  const [Steptwocount, setSteptwocount] = useState();

  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
    setSteptwocount(2);
  };
  const gotoBack = () => {
    sliderRef.current.slickPrev();
    setSteptwocount();
    setActive(1);
  };

  const submit = (data) => {
    console.log("final", data);
  };

  const goToSlide = (slideIndex) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  };
  return (
    <Container>
      {/* <Row className='justify-content-center'>
				<Col sm={12} md={4} lg={5}>
					<ClientCount />
				</Col>
			</Row> */}
      <hr className="hr-1"></hr>
      <Row className=" jsutify-content-center text-center mt-lg-4 my-sm-2">
        <Col>
          <h2 className="maintag-calculator my-2">Offer Calculator</h2>
          <p className="tag-line-calculator my-2">
            No Wait, No Drama, No Salesman!
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col sm={12} md={8} lg={7} className="">
          <br />
          <div className="justify-content-center text-center">
            {/* <h2 className='maintag-calculator fw-bolder'>Calculate Offer</h2> */}
          </div>

          <Card className=" main-form-layout px-1 py-0">
            <Card.Body className="px-2 py-0">
              <Card className="d-none stepper-align border-0">
                <Card.Body className="py-1">
                  <div className="d-flex justify-content-center">
                    <div className="circular-tick">{1}</div>
                    <div className="bar-style">
                      {" "}
                      {active} of {totalSteps}
                    </div>
                    <div className="circular-tick">{Steptwocount}</div>
                  </div>
                </Card.Body>
              </Card>
              <FormContext.Provider
                value={{
                  active,
                  setActive,
                  formData,
                  setFormData,
                  LCPForm,
                  setLCPForm,
                  gotoNext,
                  gotoBack,
                  submit,
                  goToSlide,
                  LumpsumformData,
                  setLumpsumFormData,
                }}
              >
                <div>
                  <Slider ref={sliderRef} {...settings}>
                    <div>
                      <StepOne />
                    </div>
                    <div>
                      <StepTwo />
                    </div>
                    <div>
                      <StepThree />
                    </div>
                    <div>
                      <StepFour />
                    </div>
                  </Slider>
                </div>

                {/*  <Button onClick={() => goToSlide(2)}>got back </Button> <Button onClick={() => setActive(2)}>got back </Button> */}
              </FormContext.Provider>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
