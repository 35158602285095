import { ErrorMessage, Field, Formik, useFormik } from "formik";
import { FormContext } from "../CalculatorForm";
import React, { useContext, useState, useEffect } from "react";
import { Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import {
  Height_Ranges,
  WEIGHT,
  Yes_No,
  Health_Status,
  Yes_No_prefer,
  CH,
  GENDER,
  AGE_MALE_Female,
} from "../FormConstants";
import { schema } from "./StepFourSchema";
import { initialValues } from "./StepFourValues";
import { useNavigate } from "react-router-dom";
export const StepFour = (props) => {
  const navigate = useNavigate();
  const { goToSlide, LumpsumformData, setLumpsumFormData } =
    useContext(FormContext);
  const { values, errors, touched, handleChange, handleSubmit, setTouched } =
    useFormik({
      initialValues,
      validationSchema: schema,
      onSubmit: (values, action) => {
        values.Age = parseInt(values.Age);
        const datalumpsum = { ...LumpsumformData, ...values };
        setLumpsumFormData(datalumpsum);
        console.log("******", datalumpsum);
        navigate(`/results/userRequest=lumpsumPayment`, {
          state: datalumpsum,
        });
      },
    });

  return (
    <Form
      autoComplete="off"
      className="form-two-Style px-1 mb-2"
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Row className="my-2">
        <Form.Group
          as={Col}
          md="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">Gender</Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.Gender}
              name="Gender"
              isInvalid={touched.Gender && !!errors.Gender}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ Gender: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {GENDER.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.Gender && touched.Gender ? (
            <span className="error-invlaid ">{errors.Gender}</span>
          ) : null}
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">Height</Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.Height}
              name="Height"
              isInvalid={touched.Height && !!errors.Height}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ Height: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {Height_Ranges.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.Height && touched.Height ? (
            <span className="error-invlaid ">{errors.Height}</span>
          ) : null}
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Weight (lbs)
          </Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.Weight}
              name="Weight"
              isInvalid={touched.Weight && !!errors.Weight}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ Weight: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {WEIGHT.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.Weight && touched.Weight ? (
            <span className="error-invlaid ">{errors.Weight}</span>
          ) : null}
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">Age</Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={+values.Age}
              name="Age"
              isInvalid={touched.Age && !!errors.Age}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ Age: false });
              }}
            >
              {AGE_MALE_Female.map((item) => {
                return <option value={+item.value}>{item.label}</option>;
              })}
              <option value="" selected disabled hidden>
                Select....
              </option>
            </Form.Select>
          </InputGroup>
          {errors.Age && touched.Age ? (
            <span className="error-invlaid ">{errors.Age}</span>
          ) : null}
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Do you Smoke?
          </Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.Smoke}
              name="Smoke"
              isInvalid={touched.Smoke && !!errors.Smoke}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ Smoke: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {Yes_No.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.Smoke && touched.Smoke ? (
            <span className="error-invlaid ">{errors.Smoke}</span>
          ) : null}
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Health Profile
          </Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.Health}
              name="Health"
              isInvalid={touched.Health && !!errors.Health}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ Health: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {Health_Status.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.Health && touched.Health ? (
            <span className="error-invlaid ">{errors.Health}</span>
          ) : null}
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          lg="6"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Major Traffic Violations?
          </Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.TrafficVoilation}
              name="TrafficVoilation"
              isInvalid={touched.TrafficVoilation && !!errors.TrafficVoilation}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ TrafficVoilation: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {Yes_No_prefer.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.TrafficVoilation && touched.TrafficVoilation ? (
            <span className="error-invlaid ">{errors.TrafficVoilation}</span>
          ) : null}
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          lg="6"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Cardiac Health
          </Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.CardiaticHealth}
              name="CardiaticHealth"
              isInvalid={touched.CardiaticHealth && !!errors.CardiaticHealth}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ CardiaticHealth: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {CH.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.CardiaticHealth && touched.CardiaticHealth ? (
            <span className="error-invlaid ">{errors.CardiaticHealth}</span>
          ) : null}
        </Form.Group>
      </Row>

      <div className="text-center  mt-5">
        <Button
          variant="secondary"
          onClick={() => goToSlide(2)}
          className="mx-2  prev-Button-step-two"
        >
          BACK
        </Button>
        <Button
          className="submt-Button-step-two mx-2"
          variant="success"
          type="submit"
        >
          CALCULATE
        </Button>
      </div>
    </Form>
  );
};
