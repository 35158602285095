import * as yup from "yup";
export const schema = yup.object().shape({
	// firstName: yup
	// 	.string()
	// 	.matches(/^[A-Za-z ]*$/, "Please enter valid name")
	// 	.max(40)
	// 	.required(),
	PaymentType: yup.string().required("Please select Payment Type"),
	PaymentMode: yup.string().required("Please select Payment Mode"),
	// firstName: yup.string().min(2).max(25).required("Your Name is required"),
	Age: yup.string().required("Please select your Age"),
	PaymentAmount: yup
		.number()
		.required("Enter Payment Amount")
		.min(100, "Minimum amount is 100 $")
		.max(10_000_00, "Max allowed amount is 10,000,00 $"),
	PaymentStartDate: yup
		.date()
		.min(new Date(), "Start date cannot be in the past")
		.required("Start date is required"),
	PaymentEndDate: yup
		.date()
		.when("PaymentStartDate", (PaymentStartDate, schema) => {
			if (PaymentStartDate) {
				const currentDay = new Date(PaymentStartDate.getTime());
				return schema.min(currentDay, "End date must be after start date");
			} else {
				return schema;
			}
		})
		.required("End date is required"),
});
