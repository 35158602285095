import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./OurServices.css";

const OurServices = () => {
	return (
		<Container fluid>
			<hr className='hr-1'></hr>
			<Row className='justify-content-center'>
				<Col className='text-center mb-2' lg={6}>
					<h1 className='tag-color-ourServices mb-3'>Our Services </h1>
					<p
						style={{
							textAlign: "center",
							letterSpacing: "1px",
							fontSize: "18px",
							fontWeight: "500",
						}}
						className='mb-4'>
						A comprehensive suite of services empowers you with the tools to
						shape your financial future.
					</p>
				</Col>
			</Row>
			<Row className=''>
				<Col lg={6} md={12} className='px-lg-5 px-sm-1 mb-3'>
					<Card
						style={{ border: "1px solid #fccc64" }}
						className='text-center h-100'>
						<Card.Body>
							<h5 style={{ color: "#f5ae2b" }} className='mb-2 fw-light'>
								Early Payouts Simplified
							</h5>
							<p style={{ textAlign: "center", fontSize: "14px" }}>
								Empower yourself with our structured settlement sales service.
								Our platform facilitates obtaining competitive offers without
								disclosing personal information. Leverage our advanced structure
								settlement calculator for precise insights, enabling well-
								informed financial decisions.
							</p>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={6} md={12} className='px-lg-5 px-sm-1 mb-3'>
					<Card
						style={{ border: "1px solid #fccc64" }}
						className='text-center  h-100'>
						<Card.Body>
							<h5 style={{ color: "#f5ae2b" }} className='mb-2 fw-light'>
								Expert Support
							</h5>
							<p style={{ textAlign: "center", fontSize: "14px" }}>
								Navigating the intricacies of structured settlements can be
								challenging. Count on our experts' insights and unwavering
								support, whether you're selling your settlement payments or
								exploring financial options. Start your journey towards a
								smarter payout today.
							</p>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default OurServices;
