import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

/**
 * @author
 * @function TermandConditionOverlay
 **/

const popover = (
	<Popover id='popover-basic'>
		<Popover.Header as='h3'>Terms & Conditions</Popover.Header>
		<Popover.Body className='text-center'>
			By submitting my phone number, I am providing Smarter Payouts my written
			consent to contact me regarding product offerings by SMS/text messages or
			a call at the phone number(s).Furthermore I agree to the term and
			conditions provide on the website.
		</Popover.Body>
	</Popover>
);
export const TermandConditionOverlay = (props) => {
	return (
		<OverlayTrigger trigger='click' placement='top' overlay={popover}>
			<p variant='success' className='mt-4'>
				<u>
					<b>Terms & Conditions</b>
				</u>
			</p>
		</OverlayTrigger>
	);
};
