import React, { useState, useEffect } from "react";

function CountdownTimer({ initialHours, initialMinutes, initialSeconds }) {
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(timer);
        // Handle timer completion logic here
      } else {
        if (seconds === 0) {
          if (minutes === 0) {
            setHours(hours - 1);
            setMinutes(59);
          } else {
            setMinutes(minutes - 1);
          }
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [hours, minutes, seconds]);

  return (
    <div style={{ textAlign: "center" }}>
      <h6 style={{ color: "gray" }}>Offer Expires In</h6>
      <div className="fw-bold" style={{ fontSize: "20px" }}>
        <span style={{ color: "red" }}>
          {hours.toString().padStart(2, "0")}
        </span>
        &nbsp;:&nbsp;
        <span style={{ color: "red" }}>
          {minutes.toString().padStart(2, "0")}
        </span>
        &nbsp;:&nbsp;
        <span style={{ color: "red" }}>
          {seconds.toString().padStart(2, "0")}
        </span>
      </div>
    </div>
  );
}

export const Timer = () => {
  return (
    <div>
      <CountdownTimer initialHours={8} initialMinutes={0} initialSeconds={0} />
    </div>
  );
};
