import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { GetInTouch } from "../GetInTouch/GetInTouch";
import "./Disclaimer.css";
export const Disclaimer = () => {
  return (
    <div className="">
      <Container fluid>
        <Row className="my-4">
          <Col className="text-center">
            <Card className="main-header-bg">
              <Card.Body>
                <h3 className="text-white">
                  Terms and Conditions for Smarter Payouts
                </h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="mx-lg-5 mx-sm-1">
          <Row className="justify-content-center d-flex">
            <Col lg={8} md={12}>
              <p className="letter-sp-text">
                By providing my phone number to “Smart Settlement Inc”, I agree
                and acknowledge that 'Smart Settlement Inc' may send text
                messages to my wireless phone number for any purpose. Message
                and data rates may apply. Message frequency will vary, and you
                will be able to opt-out by replying 'STOP'. For more information
                on how your data will be handled, please visit Smart Settlement
                Inc's{" "}
                <a className="text-tag-detail" href="/POLICIES" target="_blank">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a className="text-tag-detail" href="/TAC" target="_blank">
                  {" "}
                  TERMS & CONDITIONS
                </a>
                .
              </p>
            </Col>
          </Row>
        </div>
        {/* <GetInTouch /> */}
      </Container>
    </div>
  );
};
