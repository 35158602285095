export const ANNUAL_INCREASE = Array.from(Array(7).keys());
export const AGE_MALE_Female = [
	{ label: "18-30", value: 25 },
	{ label: "31-42", value: 36 },
	{ label: "43-55", value: 49 },
	{ label: "56-68", value: 62 },
	{ label: "69+", value: 75 },
];
export const Height_Ranges = [
  { label: "4'0''- 4'9''", value: "4.5" },
  { label: "4'10''- 5'3''", value: "5.0" },
  { label: "5'4''- 5'10''", value: "5.7" },
  { label: "5'11''- 6'4''", value: "6.1" },
  { label: "6'5''- 7'4''", value: "6.10" },
  //{ label: "6''3-7''0'", value: "6.5" },
  //{ label: "7'' +", value: "7.2" },
];

export const WEIGHT = [
  //{ label: "55-75", value: 65 },
  { label: "76-100", value: 88 },
  { label: "101-125", value: 113 },
  { label: "126-150", value: 138 },
  { label: "151-175", value: 163 },
  { label: "176-200", value: 188 },
  { label: "201-225", value: 213 },
  { label: "226-250", value: 238 },
  { label: "251-275", value: 263 },
  { label: "276-300", value: 288 },
];
export const Yes_No = ["Yes", "No"];
export const Health_Status = ["Great", "Normal", "Fair", "Below Fair"];
export const Yes_No_prefer = ["Yes", "No", "Prefer Not to Answer"];
export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Prefer not to answer", value: "Male" },
];
export const CH = [
  "Normal",
  "Medicated",
  "High",
  "Not Sure",
  // "Prefer Not to Answer",
];
export const Payment_Mode = [
  "Monthly",
  "Quarterly",
  "Semiannually",
  "Annually",
  "Lumpsum",
];
export const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	swipe: false,
	swipeToSlide: false,
};
