import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import { RawServer, SERVER } from "../../../../constant/AppConstants";
import axios from "axios";
import { Col, Container, Row, Card, ListGroup } from "react-bootstrap";
import "./Calculation.css";
import { numberWithCommas } from "./layoutUtils";
import { VerificationLayout } from "../VerificationLayout/VerificationLayout";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import firebaseApp from "../../../../firebase/firebase";
import { SessionExpired } from "../NotificationsPopups/SessionExpired";
import { GobackPopup } from "../NotificationsPopups/GobackPopup/GobackPopup";
import LoadingScreen from "react-loading-screen";
const overlay = require("../../assets/img/logo.png");

/**
 * @author
 * @function Calculation
 **/

export const Calculation = (props) => {
  const [max, setMax] = useState("$ $ $");
  const [min, setMin] = useState("$ $");
  const [FM, setFM] = useState("$ $");
  const [paymentType, setPaymentType] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [name, setName] = useState();
  const [sessionExpired, setsessionExpired] = useState(false);
  const [verifyingLayputHandler, setverifyingLayputHandler] = useState(false);
  const [GoBack, setGoBack] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const datafetcher = async () => {
    let datafetch = location.state;
    if (datafetch.lumpsumPayments) {
      var response = await axios.post(
        `${SERVER}api/lumpsum/Lumpsum`,
        datafetch
      );
      console.log("please new", response);
    } else {
      var response = await axios.post(
        `${SERVER}api/calculation/Calculate`,
        datafetch
      );
      console.log("please old", response.data);
    }
    console.log("please", response);
    if (response.data.status == 200) {
      let type = +datafetch.ProductType || +datafetch.paymentType;
      setPaymentType(type);
      let max = response?.data?.pva;
      let min = response?.data?.pvc;
      let ben_amount = response?.data.ben_benfit;
      if (max <= 9999 || min <= 9999 || min >= max) {
        setMax(0);
        setMin(0);
        setFM(0);
        setShowLoader(false);
        setName("You are not Eligible");
      } else {
        setName(location.state.FirstName);
        let maxStandard = numberWithCommas(max?.toFixed(2));
        let minStandard = numberWithCommas(min?.toFixed(2));
        let ben_amountStandard = numberWithCommas(ben_amount?.toFixed(2));
        setMax(maxStandard);
        setMin(minStandard);
        setFM(ben_amountStandard);
        setShowLoader(false);
        showModal();
      }
    } else {
      alert("calcualtion error");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (location.state) {
        await datafetcher();
        navigate(location.pathname, {});
      } else {
        setsessionExpired(true);
      }
      setShowLoader(false);
    };
    fetchData();
    console.log("data", location);
  }, []);

  const showModal = () => {
    setTimeout(() => setverifyingLayputHandler(true), 6000);
  };

  const verifiedDetailscheck = () => {
    setverifyingLayputHandler(false);
    setShowLoader(false);
  };

  const existNote = () => {
    setGoBack(true);
  };

  const SessionExpiredManagement = () => {
    navigate("/");
  };
  console.log("data", location);
  return (
    <Container fluid className="calculation-form-layout">
      <Row>
        <LoadingScreen
          loading={showLoader}
          bgColor="#f1f1f1"
          spinnerColor="#54d000"
          textColor="#676767"
          logoSrc={overlay}
          text="Calculating Your Payout"
        >
          <div id="sign-in-button"></div>
          <VerificationLayout
            // show={true}
            show={verifyingLayputHandler}
            verifydetail={verifiedDetailscheck}
            onHide={existNote}
          />
          <SessionExpired
            show={sessionExpired}
            // show={false}
            redirector={SessionExpiredManagement}
          />
          <GobackPopup show={GoBack} getquote={() => setGoBack(false)} />
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <img
                alt="logo"
                className="mb-2"
                width={90}
                height={90}
                src={require("../../assets/img/NewLogo.png")}
              />
              <h4
                style={{ color: max != 0 ? "#54d000" : "red" }}
                className="fw-light mb-2"
              >
                {name}
              </h4>
              {max == 0 ? (
                <p className="mb-4">
                  We apologize, but our system indicates that you are not
                  currently eligible &#x1F641;.
                </p>
              ) : (
                <p className="text-center mb-4" style={{ fontSize: "14px" }}>
                  Here’s your Early Payout offer based on the information
                  provided. Funding in as little as 30 days.
                </p>
              )}

              <Card
                className="text-center mt-3"
                style={{ borderRadius: "15px", border: "none" }}
              >
                <Card.Header className="calculation-top-header">
                  <p className="min-max-text-color">Minimum Offer </p>
                  <p
                    className="fw-bold text-white"
                    style={{ fontSize: "18px", color: "white" }}
                  >
                    {min}
                  </p>
                </Card.Header>
                <ListGroup className="my-3" variant="flush">
                  <ListGroup.Item
                    style={{ color: "#c3e9b6" }}
                    className="center-color-max"
                  >
                    <p className="min-max-text-color-center">Maximum Offer</p>
                    <p
                      style={{ fontSize: "22px", color: "gray" }}
                      className="min-max-text-color-center-amount fw-bold "
                    >
                      {max}
                    </p>
                  </ListGroup.Item>
                </ListGroup>
                <Card.Header className="calculation-top-header-left">
                  <p className="min-max-text-color">Family Protection</p>
                  <p
                    style={{ fontWeight: "16px" }}
                    className="fw-bold text-white"
                  >
                    {paymentType == 2 && min != 0 ? `$ 25,000.00 ` : FM}
                  </p>
                </Card.Header>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col md={8} className="text-center mt-1">
              {max == 0 ? <p>Need More Info?</p> : <h6>Call Us</h6>}
              <p className="mb-3 mt-1">
                <a
                  style={{
                    color: "#54d000",
                    textDecoration: "none",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                  href="tel:+18669729688"
                >
                  +1 (561) -583-1280
                </a>
              </p>
            </Col>
          </Row>
        </LoadingScreen>
      </Row>
    </Container>
  );
};
