import { useFormik } from "formik";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import React, { useContext, useState, useEffect } from "react";
import { FormContext } from "../CalculatorForm";
import { useNavigate } from "react-router-dom";
import "./StepThree.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import { Payment_Mode } from "../FormConstants";
import { Scrollbars } from "react-custom-scrollbars";
//csss

const initialValues = {
  paymentType: "", // New field for payment type
  lumpsumPayments: [{ amount: "", date: "" }],
};

export const StepThree = (props) => {
  const [selectedPayment, setSelectedPayment] = useState();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("Lumpsum");
  const navigate = useNavigate();
  const { goToSlide, LumpsumformData, setLumpsumFormData } =
    useContext(FormContext);

  const handleSubmitlumpsumOne = (values) => {
    console.log(values);
    const datalumpsum = { ...LumpsumformData, ...values };
    setLumpsumFormData(datalumpsum);
    console.log(datalumpsum.paymentType);
    if (+datalumpsum.paymentType === 2) {
      navigate(`/results/userRequest=lumpsumPayment`, {
        state: datalumpsum,
      });
    } else {
      goToSlide(3);
    }
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = { lumpsumPayments: [] };
      let hasError = false;

      values.lumpsumPayments.forEach((row, index) => {
        const rowErrors = {};
        if (!row.amount) {
          rowErrors.amount = "Amount is required";
          hasError = true;
        }
        if (!row.date) {
          rowErrors.date = "Date is required";
          hasError = true;
        }
        errors.lumpsumPayments[index] = rowErrors;
      });
      if (!values.paymentType) {
        errors.paymentType = "Payment type is required";
        hasError = true;
      }
      return hasError ? errors : {};
    },
    onSubmit: (values) => {
      console.log("Form submitted with values:", values);
      handleSubmitlumpsumOne(values);
    },
  });

  const addRow = () => {
    if (formik.values.lumpsumPayments.length < 10) {
      const newRow = { amount: "", date: "" };
      formik.values.lumpsumPayments.push(newRow);
      formik.setValues({ ...formik.values });
    }
  };

  const removeRow = (index) => {
    if (formik.values.lumpsumPayments.length > 1) {
      formik.values.lumpsumPayments.splice(index, 1);
      formik.setValues({ ...formik.values });
    }
  };
  const paymentCustomFunction = (value) => {
    formik.setFieldValue("paymentType", value);
    setSelectedPayment(value);
    console.log("------", value);
  };
  const selectModes = () => {
    if (formik.values.lumpsumPayments.length > 1) {
      formik.values.lumpsumPayments.splice(
        1,
        formik.values.lumpsumPayments.length - 1
      );
      formik.setValues({ ...formik.values });
    }
    console.log();
    goToSlide(0);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={6} lg={6}>
          <Form.Group controlId="validationFormikUsername">
            <Form.Label className="custom-label fw-bolder">
              Payment Mode
            </Form.Label>

            <InputGroup hasValidation>
              <Form.Select
                size="md"
                value={selectedPaymentMode}
                onChange={(e) => selectModes(e)}
              >
                <option value="" selected disabled>
                  Select....
                </option>
                {Payment_Mode.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </Form.Select>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6} lg={6}>
          <Form.Group>
            <Form.Label className="custom-label fw-bolder">
              Payment Type
            </Form.Label>
            <Form.Control
              as="select"
              name="paymentType"
              onChange={(e) => {
                paymentCustomFunction(e.target.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.paymentType}
            >
              <option value="" disabled>
                Select....
              </option>
              <option value="1">Life Payment (LCP)</option>
              <option value="2">Guaranteed Payments (GP)</option>
              <option value="0">I Don’t Know</option>
            </Form.Control>
            {formik.touched.paymentType && formik.errors.paymentType && (
              <Form.Text className="text-danger">
                {formik.errors.paymentType}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        {formik.values.lumpsumPayments.map((row, index) => (
          <Form.Group key={index} as={Col} md="12" lg="4">
            <Card
              className="mt-2 px-2 py-2"
              style={{ backgroundColor: "transparent" }}
            >
              <Row>
                <Col lg={12} md={12}>
                  <Form.Label className="custom-label fw-bolder d-flex justify-content-between">
                    <div> Lumpsum {index + 1} </div>{" "}
                    <Button
                      className="py-0"
                      variant="outline-danger"
                      onClick={() => removeRow(index)}
                    >
                      x
                    </Button>
                  </Form.Label>
                  <CurrencyInput
                    className="form-control mb-2"
                    name={`lumpsumPayments[${index}].amount`}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    placeholder="$500.00"
                    defaultValue={1000}
                    prefix=" $"
                    onValueChange={(value) => {
                      formik.setFieldValue(
                        `lumpsumPayments[${index}].amount`,
                        value
                      );
                    }}
                    value={row.amount}
                  />
                  {formik.touched.lumpsumPayments?.[index]?.amount &&
                    formik.errors.lumpsumPayments?.[index]?.amount && (
                      <Form.Text className="text-danger">
                        {formik.errors.lumpsumPayments[index].amount}
                      </Form.Text>
                    )}{" "}
                  <Form.Control
                    type="date"
                    name={`lumpsumPayments[${index}].date`}
                    placeholder="Date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={row.date}
                  />{" "}
                  {formik.touched.lumpsumPayments?.[index]?.date &&
                    formik.errors.lumpsumPayments?.[index]?.date && (
                      <Form.Text className="text-danger">
                        {formik.errors.lumpsumPayments[index].date}
                      </Form.Text>
                    )}
                </Col>
              </Row>
            </Card>
          </Form.Group>
        ))}
      </Row>
      <Row className="mt-4 mb-3">
        <Col lg={6} md={12} className="text-center my-1">
          {formik.values.lumpsumPayments.length !== 10 && (
            <Button
              className="main-Button-lumpsum"
              variant="primary"
              type="button"
              onClick={addRow}
            >
              <FontAwesomeIcon
                style={{
                  marginRight: "5px",
                }}
                icon={faPlus}
              />
              Add More Payment
            </Button>
          )}
        </Col>
        <Col lg={6} md={12} className="text-center my-1">
          <Button
            style={
              selectedPayment == 2
                ? { backgroundColor: "rgb(245, 174, 43)" }
                : { backgroundColor: "#54d000" }
            }
            className="main-Button-lumpsum"
            type="submit"
          >
            {selectedPayment == 2 ? "CALCULATE" : "NEXT"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
