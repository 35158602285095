import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/AdminApp/login/Login";
import ClientsList from "./components/clientsList/ClientsList";
import LandingPage from "./components/landingpage/LandingPage";
import { Calculation } from "./components/newDesign/CalculatorForm/CalculationLayout/Calculation";
import { CalculatorForm } from "./components/newDesign/CalculatorForm/CalculatorForm";
import { PrivacyPlicy } from "./components/newDesign/privacyPolicy/PrivacyPolicy";
import { TermsAndCondition } from "./components/newDesign/TermsAndCondition/TermsAndCondition";
import { Disclaimer } from "./components/newDesign/Disclaimer/Disclaimer";


const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/results/:userName" element={<Calculation />} />
        <Route path="/Cal" element={<CalculatorForm />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/MasterLogin" element={<Login />} />
        <Route path="/ClientLists" element={<ClientsList />} />
        <Route path="/TAC" element={<TermsAndCondition />}></Route>
        <Route path="/POLICIES" element={<PrivacyPlicy />}></Route>
        <Route path="/Disclaimer" element={<Disclaimer />}></Route>
      </Routes>
      <footer className="footer" style={{ textAlign: "center" }}>
        <p>
          {" "}
          <h6 style={{ color: "#54d000", fontWeight: "400", fontSize: "14px" }}>
            &copy; {new Date().getFullYear()} SmarterPayouts. All rights
            reserved.
          </h6>
        </p>
      </footer>
    </div>
  );
};

export default App;
