import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./AboutUs.css";
import ImageThumbnail from "../assets/media/aboutus.png";
import video from "../assets/media/Smarter-Payouts last.mp4";
import ReactPlayer from "react-player";
import "./AboutUs.css";

/**
 * @author
 * @function AboutUs
 **/

export const AboutUs = (props) => {
	return (
		<Container fluid className='pb-5 mt-3 '>
			<hr className='hr-1'></hr>
			<Row className='justify-content-center'>
				<Col lg={6}>
					<div className='text-center mb-3'>
						<h1 className='tag-color-aboutUs'>About Us</h1>
						<p
							className='text-style-aboutus  text-center'
							style={{ fontSize: "18px" }}>
							Upfront pricing that’s done right!
						</p>
					</div>
				</Col>
			</Row>

			<Row className='aboutus-layer-smarter justify-content-center'>
				<Col lg={7} md={12}>
					<div className='card-color player-wrapper '>
						<ReactPlayer
							className='react-player'
							url={"https://www.youtube.com/watch?v=AYFvqQCCSoY"}
							controls
							width='100%'
							height='100%'
						/>
					</div>
				</Col>
			</Row>
			<hr className='hr-1'></hr>
			<Row className=' mt-3'>
				<Col lg={12} md={12} className='we-promise-layer-smarter'>
					<Row className='justify-content-center'>
						<Col className='text-center mb-2 mt-3' lg={12}>
							<h1 className='tag-color-ourServices mb-3'>Our Mission </h1>
							<p
								style={{
									textAlign: "center",
									letterSpacing: "1px",
									fontSize: "18px",
									fontWeight: "500",
								}}
								className='mb-4'>
								We provide a user-friendly platform for exploring competitive
								quotes, and making informed decisions.
							</p>
						</Col>
					</Row>
					<Row className='justify-content-center px-0'>
						<Col className='text-center px-0' lg={12}>
							<h5 className=' pr-3 tag-color-ourServices mb-1 text-center'>
								Why Smarter Payouts?{" "}
							</h5>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col lg={4} sm={12} className='mb-3 px-lg-5 px-sm-1'>
							<Card style={{ border: "1px solid #fccc64" }}>
								<Card.Body>
									<div className='text-center'>
										<img src={require("../assets/img/emp.png")} />
										<h6
											style={{ color: "#f5ae2b" }}
											className='mt-3 fw-lighter '>
											Empowerment
										</h6>
										<p
											style={{ fontSize: "14px" }}
											className='general-text-spacing'>
											Smarter Payouts empowers you with a highly advanced smart
											platform. This ensures competitive offers without
											requiring personal information. Utilize our advanced
											calculator for precise insights, enabling well-informed
											financial decisions.
										</p>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col lg={4} md={12} sm={12} className='mb-3 px-lg-5 px-sm-1'>
							<Card style={{ border: "1px solid #fccc64" }}>
								<Card.Body className='px-1'>
									<div className='text-center '>
										<img
											width={30}
											height={30}
											src={require("../assets/img/qoute.png")}
										/>
										<h6 style={{ color: "#f5ae2b" }} className='mt-3'>
											Immediate Quote
										</h6>
										<p style={{ fontSize: "14px" }} className='px-1'>
											Secure instant lump sum quotes without disclosing personal
											information through our structured settlement sales
											service. Our platform offers competitive offers. Utilize
											our smart calculator for well-informed financial decisions
											guided by precise insights.
										</p>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col lg={4} md={12} sm={12} className='mb-3 px-lg-5 px-sm-1'>
							<Card style={{ border: "1px solid #fccc64" }}>
								<Card.Body className='px-1'>
									<div className='text-center '>
										<img
											width={30}
											height={30}
											src={require("../assets/img/obligation.png")}
										/>
										<h6 style={{ color: "#f5ae2b" }} className='mt-3'>
											Transparency
										</h6>
										<p style={{ fontSize: "14px" }} className='px-1'>
											Our commitment to transparency shines through. Experience
											it with our platform sales service, ensuring open
											communication at every stage. Obtain competitive offers
											without providing personal information. Utilize our free
											smart calculator for an accurate estimate.
										</p>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};
