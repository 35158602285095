import React from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import "./WhySmarter.css";
import ReactPlayer from "react-player";
import ImageThumbnail from "../assets/media/intros.png";
import video from "../assets/media/Smarter-Payouts.mp4";
import { BrowserView } from "react-device-detect";

/**
 * @author
 * @function WhySmarter
 **/

export const WhySmarter = (props) => {
	return (
		<Container className='main-body-why-smarter' fluid>
			<Row className='justify-content-center second-layer-smarter mb-4'>
				<Col lg={8} md={11}>
					<Card className='border-0'>
						<Card.Body className='text-center m'>
							<h2 className='tag-color my-lg-3 my-xs-3 fw-light'>
								Smarter Payouts
							</h2>
							<p
								style={{ color: "rgb(119, 118, 118)" }}
								className='info-text mt-1 mb-1'>
								A self-pricing platform that calculates an Early Payout offer
								for future settlement payments.
							</p>
						</Card.Body>
					</Card>
				</Col>
				<Row className='justify-content-center'>
					<Col lg={8} md={12}>
						<div className='card-color player-wrapper '>
							<ReactPlayer
								className='react-player'
								url={"https://www.youtube.com/watch?v=TxFogK2R61o"}
								//playing={true}
								controls
								//	light={ImageThumbnail}
								width='100%'
								height='100%'
							/>
						</div>
						<div className='text-center mt-3'>
							<p
								style={{ letterSpacing: "1px" }}
								className='upfront-text fw-bold text-white '>
								Upfront Offer in 2 minutes or less
							</p>
						</div>
					</Col>
				</Row>
			</Row>
		</Container>
	);
};
