import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Accordion, Col, Container, Row, Table } from "react-bootstrap";
import { SERVER } from "../../constant/AppConstants";

/**
 * @author
 * @class ClientsList
 **/

class ClientsList extends Component {
	state = {
		data: [],
	};
	componentDidMount = async () => {
		let response = await axios.get(`${SERVER}api/calculation/getAllClientList`);
		console.log(response);
		this.setState({
			data: response.data,
		});
	};

	render() {
		return (
			<Container fluid>
				<div>
					<Table responsive='lg' bordered hover className='table-header'>
						<thead className='table-header'>
							<tr>
								<th className='d-flex justify-content-between'>
									<span>OASIS TRACKING (32 Patients)</span>{" "}
									<span>
										<u>OASIS Batch Download</u>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<Accordion flush>
										{this.state.data.map((ele, index) => {
											return (
												<Accordion.Item key={index} eventKey={index}>
													<Accordion.Header className='accordion-design '>
														<Row className='w-100'>
															<Col lg={1} md={1} sm={2}>
																{index + 1}
															</Col>
															<Col lg={2} md={2} sm={3}>
																{ele.FirstName}
															</Col>
															<Col lg={2} md={2} sm={3}>
																{ele.LastName}
															</Col>
															<Col lg={1} md={1} sm={2}>
																{ele.ProductType != "2" ? "LCP" : "GP"}
															</Col>
															<Col lg={2} md={2} sm={3}>
																{ele.PaymentMode}
															</Col>
															<Col lg={2} md={2} sm={3}>
																{ele.PhoneNumber}
															</Col>
															<Col lg={2} md={2} sm={3}>
																{ele.createdAt.substring(0, 10)}
															</Col>
														</Row>
													</Accordion.Header>
													<Accordion.Body>
														<Table
															striped
															variant='dark'
															key={index}
															responsive
															bordered
															hover
															className='inner-table'>
															<thead>
																<tr>
																	<th>#</th>
																	<th colSpan={2}>Form -1</th>
																	<th colSpan={2}>Form -2</th>
																	<th colSpan={2}>Form- 3</th>
																	<th>Terms</th>
																	<th>Calculation</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>1</td>
																	<td>First Name</td>
																	<td>{ele.FirstName}</td>
																	<td>Payment Type</td>
																	<td>{ele.ProductType}</td>
																	<td>Approx. Height</td>
																	<td>{ele.Height}</td>
																</tr>

																<tr>
																	<td>2</td>
																	<td>Last Name</td>
																	<td>{ele.LastName}</td>
																	<td>Amount</td>
																	<td>$ {ele.PaymentAmount}</td>
																	<td>Approx. Weight</td>
																	<td>
																		{ele.Weight
																			? ele.Weight
																			: ele.MenuallWeight}
																	</td>
																	<td>Min Value</td>
																	<td>$3534543</td>
																</tr>
																<tr>
																	<td>3</td>
																	<td>Email</td>
																	<td>{ele.Email}</td>
																	<td>Start Date</td>
																	<td>{ele.PaymentStartDate}</td>
																	<td>Do you Smoke?</td>
																	<td>{ele.Smoker}</td>
																	<td>Max Value</td>
																	<td>$45,345,34</td>
																</tr>
																<tr>
																	<td>4</td>
																	<td>Age</td>
																	<td>{ele.Age} Years</td>
																	<td>End Date</td>
																	<td>{ele.PaymentEndDate}</td>
																	<td>Health Profile</td>
																	<td>{ele.Health}</td>
																	<td>Family protection</td>
																	<td>
																		$ {ele.GP_BEN ? ele.GP_BEN : ele.LCP_BEN}
																	</td>
																</tr>
																<tr>
																	<td>5</td>
																	<td>Gender</td>
																	<td>{ele.Gender}</td>
																	<td>Mode</td>
																	<td>{ele.PaymentMode}</td>
																	<td>DL Suspensions</td>
																	<td>{ele.LicenseSuspended}</td>
																	<td>Contact #</td>
																	<td>+2377357</td>
																</tr>
																<tr>
																	<td>6</td>
																	<td>Inssurance Company</td>
																	<td>{ele.InsuranceCompany}</td>
																	<td>Percent% step-up</td>
																	<td>{ele.PercentStep} %</td>
																	<td>Blood Pressure</td>
																	<td>{ele.BloodPressure}</td>
																</tr>
															</tbody>
														</Table>
													</Accordion.Body>
												</Accordion.Item>
											);
										})}
									</Accordion>
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</Container>
		);
	}
}

ClientsList.propTypes = {};
export default ClientsList;
