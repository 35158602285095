import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import "./Login.css";
const ImageLogo = require("../../../images/logo-tower.png").default;
/**
 * @author
 * @class Login
 **/

class Login extends Component {
	state = {};
	render() {
		return (
			<Container fluid>
				<Row>
					<Col lg={6} className='text-center'>
						<img className='w-50 h-100' src={ImageLogo}></img>
					</Col>
					<Col lg={6}> Login Form</Col>
				</Row>
			</Container>
		);
	}
}

Login.propTypes = {};
export default Login;
