import {
	faFacebook,
	faInstagramSquare,
	faTwitter,
	faYoutube,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
	faEnvelope,
	faFileWord,
	faMapMarker,
	faPhone,
	faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ListGroup, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CompanyDetail.css";
import tlogo from "../../../newDesign/assets/img/t-gray.png";

/**
 * @author
 * @function CompanyDetail
 **/

export const CompanyDetail = (props) => {
  const iconTheme = { fontSize: "30px", color: "#949494" };

  return (
    <Container fluid className="mt-xs-5 mt-5 pb-5">
      <Row className="justify-content-center">
        <Col lg={6}>
          <div className="text-center mb-4">
            <h1 className="way-to-us">Company Details</h1>
            <p
              style={{
                textAlign: "center",
                letterSpacing: "1px",
                fontSize: "18px",
                fontWeight: "500",
              }}
              className=" text-center"
            >
              Reliable Structured Settlement Sales Assistance.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mb-3 text-center">
        {/* <img
					width={60}
					height={120}
					alt='logo'
					src={require("../../../assets/img/logo-tower.png")}></img> */}

        <p></p>
      </Row>
      <Row>
        <Col lg={7} sm={12}>
          <Card className="border-0 shadow">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item className="mb-1">
                  {" "}
                  <Row>
                    <Col lg={12}>
                      <Card className="border-0">
                        <Card.Body className="py-0 ">
                          <Row>
                            <Col lg={12} xs={12}>
                              <h6 className="mb-1 text-tag-detail fw-lighter">
                                Contact Us
                              </h6>
                              <p className="mb-0 text-tag-detail-text">
                                <a
                                  style={{
                                    color: "#54d000",
                                    textDecoration: "none",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                  href="tel:+18669729688"
                                >
                                  +1 (561) -583-1280
                                </a>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    {/* <Col lg={12}>
									<div className='companydetail-text-style'>+932-43534-244</div>
								</Col> */}
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className="mb-1">
                  <Row>
                    <Col lg={12}>
                      <Card className="border-0">
                        <Card.Body className="py-0 ">
                          <Row>
                            <Col lg={12} xs={12}>
                              <h6 className="mb-1 text-tag-detail fw-lighter">
                                Email
                              </h6>
                              <p
                                style={{ fontSize: "13px" }}
                                className="mb-0 text-tag-detail-text"
                              >
                                <a
                                  style={{
                                    color: "gray",
                                    textDecoration: "none",
                                    fontSize: "18px",
                                  }}
                                  href="mailto:info@Smarterpayouts.com?subject=Payment%20Query%20from%20Smartpayouts.com"
                                >
                                  info@Smarterpayouts.com
                                </a>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    {/* <Col lg={12}>
									<div className='companydetail-text-style'>
										ArslanABC@smarterpayouts.com
									</div>
								</Col> */}
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className="mb-1">
                  <Row>
                    <Col lg={12}>
                      <Card className="border-0">
                        <Card.Body className="py-0 ">
                          <Row>
                            <Col lg={12} xs={12}>
                              <h6 className="mb-1 fw-light text-tag-detail">
                                Visit Us
                              </h6>
                              <p
                                style={{ fontSize: "18px", color: "gray" }}
                                className="mb-0 text-tag-detail-text fw-blod"
                              >
                                433 Plaza Real Suite 275, Boca Raton, FL, 33434
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    {/* <Col lg={12}>
									<div className='companydetail-text-style px-2'>
										{"     "}76500E kotlakhpat, Model Town , S-block Lahore ,
										Pakistan
									</div>
								</Col> */}
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className="mb-1">
                  <Row>
                    <Col lg={12}>
                      <Card className="border-0">
                        <Card.Body className="py-0 ">
                          <Row>
                            <Col lg={12} xs={12}>
                              <p
                                style={{ fontSize: "20px" }}
                                className="mb-0 text-tag-detail py-2"
                              >
                                <a
                                  style={{ textDecoration: "none" }}
                                  className="text-tag-detail"
                                  href="/POLICIES"
                                  target="_blank"
                                >
                                  Privacy Policy
                                </a>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <div className="ms-2 me-auto"></div>
                </ListGroup.Item>
                <ListGroup.Item className="mb-1">
                  <Col lg={12}>
                    <Card className="border-0">
                      <Card.Body className="py-0 ">
                        <Row>
                          <Col lg={12} xs={12}>
                            <p className="mb-0 text-tag-detail py-2">
                              <a
                                style={{ textDecoration: "none" }}
                                className="text-tag-detail"
                                href="/TAC"
                                target="_blank"
                              >
                                Terms and Conditions
                              </a>
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </ListGroup.Item>
                <ListGroup.Item className="mb-1">
                  <Col lg={12}>
                    <Card className="border-0">
                      <Card.Body className="py-0 ">
                        <Row>
                          <Col lg={12} xs={12}>
                            <p className="mb-0 text-tag-detail py-2">
                              <a
                                style={{ textDecoration: "none" }}
                                className="text-tag-detail"
                                href="/Disclaimer"
                                target="_blank"
                              >
                                Disclaimers
                              </a>
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={5} sm={12} className="py-1">
          <Card className="text-center h-100 border-0 ">
            <Card.Body>
              <img
                className="mb-2"
                width={330}
                height={120}
                alt="logo"
                src={require("../../assets/img/logo.png")}
              ></img>
              <h5 style={{ color: "gray" }} className="mb-4">
                Follow Us
              </h5>
              <ListGroup className="justify-content-center border-0" horizontal>
                <ListGroup.Item>
                  <a
                    href="https://www.facebook.com/profile.php?id=100089742267393&mibextid=LQQJ4d"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon style={iconTheme} icon={faFacebook} />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="px-3 py-1 ">
                  <a
                    href="https://twitter.com/smarterpayouts?s=21&t=kwsUr5ylT64JGLuc0V-f-A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="mt-1"
                      alt="twitter"
                      width={30}
                      height={30}
                      src={tlogo}
                    ></img>
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a
                    href="https://www.instagram.com/smarterpayouts/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      style={iconTheme}
                      icon={faInstagramSquare}
                    />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a
                    href="https://www.linkedin.com/in/smarter-payouts-b37538264/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon style={iconTheme} icon={faLinkedin} />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a
                    href="https://www.youtube.com/channel/UCBqY5DIswfGQTIE9YLDztcQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon style={iconTheme} icon={faYoutube} />
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
