import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Alert,
  Container,
  Badge,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import firebaseApp from "../../../../firebase/firebase";
import OtpInput from "react-otp-input";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faDollarSign,
  faQuestionCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/fontawesome-free-solid";
import "./VerificationLayout.css";
import toast, { Toaster } from "react-hot-toast";
import { RawServer, SERVER } from "../../../../constant/AppConstants";
import axios from "axios";
import { TermandConditionOverlay } from "./TermandConditionOverlay";
import { Timer } from "../../Timer";

const phoneLogo = require("../../assets/img/logo.png");

/**
 * @author
 * @function VerificationLayout
 **/

export const VerificationLayout = (props) => {
  const [Phone, setPhone] = useState();
  const [sentNumber, setsentNumber] = useState(true);
  const [otpcode, setOtpCode] = useState("000000");

  const themeToaster = {
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
    },
    iconTheme: {
      primary: "#713200",
      secondary: "#FFFAEE",
    },
    duration: 6000,
  };

  const numberTheme = {
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
    },
    iconTheme: {
      primary: "#713200",
      secondary: "#FFFAEE",
    },
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    const phoneNumber = "+" + Phone;
    console.log(phoneNumber);
    const appVerifier = window.recaptchaVerifier;
    const auth = getAuth(firebaseApp);
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        console.log("=>=>=>", confirmationResult);
        window.confirmationResult = confirmationResult;
        toast.success(
          `We have sent you a verification (OTP) code on +${Phone}. Please check your Phone`,
          themeToaster
        );
        setsentNumber(false);
      })
      .catch((error) => {
        toast.error(error.message);
        //toast.error(error.code);
        setsentNumber(true);
      });
  };

  const verifyCode = () => {
    const code = otpcode;
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        const user = result.user;
        console.log(user);
        toast.success(
          `Phone Number ${user.phoneNumber} Verified Successfully.`,
          numberTheme
        );
        let userNumber = user.phoneNumber;
        updateVerifiedsaver(userNumber);
        setTimeout(() => props.verifydetail(), 3000);
      })
      .catch((error) => {
        toast.error(error.code);
      });
  };
  const updateVerifiedsaver = async (phoneNumber) => {
    let obj = { PhoneNumber: phoneNumber };
    let updateAPI = await axios.put(
      `${SERVER}api/updatenumber/updatePhone`,
      obj
    );
    console.log("=updted==>", updateAPI);
  };
  useEffect(() => {
    let auth = getAuth(firebaseApp);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          this.onSignInSubmit();
          console.log("recapcha");
        },
        defaultCountry: "US",
      },
      auth
    );
  }, []);

  const handleOnChange = (value) => {
    // Use a regular expression to enforce the +1 prefix
    const regex = /^\+1\d*$/;

    if (regex.test(value)) {
      setPhone(value);
    } else {
      // Keep the +1 prefix if it's removed or input is invalid
      setPhone("+1");
    }
  };

  return (
    <Container>
      <Modal {...props} fullscreen={true}>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Toaster position="top-center" reverseOrder={false} />
        <Modal.Body className="text-center modal-form-layout">
          <Row className="justify-content-center mb-3">
            <Col className=" justify-content-center">
              <p style={{ fontSize: "18px" }} className="mb-2 ">
                <b>Claim Your Financial Freedom</b>
              </p>

              <img
                height={130}
                width={150}
                alt="mint"
                src={require("../../assets/img/moneypot.png")}
              />
              <div className="justify-content-center d-flex">
                {" "}
                <Card
                  className="tag-car-offer"
                  text="dark"
                  style={{ width: "250px", borderRadius: "15px" }}
                >
                  <Card.Body>
                    <h6 style={{ color: "gray" }}>Collect up to</h6>
                    <h5>$ 3,250.00</h5>
                    <Timer />
                  </Card.Body>
                </Card>
              </div>
              <div className="ml-5">
                {sentNumber ? (
                  <Row className="justify-content-center">
                    <Col className="justify-content-center">
                      <p style={{ fontSize: "17px" }} className="mb-3 mt-1 ">
                        <b>Sign-On Bonus Code Via Text </b>
                      </p>

                      <div className="d-flex justify-content-center pl-0">
                        <PhoneInput
                          autoComplete="off"
                          onlyCountries={["us"]}
                          country={"us"}
                          value={Phone}
                          countryCodeEditable={false}
                          onChange={(phone) => setPhone(phone)}
                          placeholder="+1 972-432-0000"
                          containerClass=""
                          style={{
                            height: "35px",
                            borderRadius: "10px",
                            borderColor: "lightgray",
                          }}
                          inputStyle={{
                            "&:focus": {
                              borderColor: "red",
                              marginBottom: "4px",
                            },
                          }}
                        />
                      </div>

                      <Row className="justify-content-center mr-3">
                        <Col className="text-center justify-content-center">
                          <Button
                            style={{
                              width: "260px",
                              backgroundColor: "#f5ae2b",
                            }}
                            onClick={(e) => onSignInSubmit(e)}
                            className="send-number mt-3 text-black "
                          >
                            <p className=" text-black mb-0 fw-bold">
                              {" "}
                              Continue{" "}
                            </p>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <h3
                        style={{ fontSize: "20px" }}
                        className="d-none fw-bolder"
                      >
                        Save Your Offer
                      </h3>
                      <p style={{ fontSize: "14px" }} className=" ">
                        <b> Code is sent to + {Phone}</b>
                      </p>
                      <OtpInput
                        isInputNum={true}
                        containerStyle="opt-class-container"
                        inputStyle="input-style-otp"
                        placeholder="12356"
                        value={otpcode}
                        onChange={(code) => setOtpCode(code)}
                        numInputs={6}
                      />
                      <p style={{ fontSize: "14px" }} className="mb-2 ">
                        Didn't receive the code?{" "}
                        <u>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => setsentNumber(true)}
                          >
                            Request new code
                          </span>
                        </u>
                      </p>
                    </Col>
                    <Row>
                      <Col style={{ paddingLeft: "40px" }}>
                        <Button
                          onClick={(e) => verifyCode(e)}
                          className="large mt-3 mb-2 verify-code-button"
                        >
                          Verify and get the offer
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                )}
              </div>
            </Col>
            <Row className="justify-content-center mt-2">
              <Col md={8} className="text-center">
                <h6 className="mt-2">Call Us</h6>

                <p className="mb-3 mt-1">
                  <a
                    style={{
                      color: "#54d000",
                      textDecoration: "none",
                      fontSize: "25px",
                      fontWeight: "bolder",
                    }}
                    href="tel:+18669729688"
                  >
                    +1 (561) -583-1280
                  </a>
                </p>

                {/* <TermandConditionOverlay /> */}
                <p className="mt-3" style={{ fontSize: "12px" }}>
                  &copy; {new Date().getFullYear()} SmarterPayouts.
                </p>
              </Col>
            </Row>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
