export const initialValues = {
  Gender: "",
  Height: "",
  Weight: "",
  Age: "",
  Smoke: "",
  Health: "",
  TrafficVoilation: "",
  CardiaticHealth: "",
};
