import React from "react";
import { GetInTouch } from "../GetInTouch/GetInTouch";
import { Container, Row, Col, Card } from "react-bootstrap";

/**
 * @author
 * @function PrivacyPlicy
 **/

export const PrivacyPlicy = (props) => {
	return (
    <div className="">
      <Container fluid>
        <Row className="my-4">
          <Col className="text-center">
            <Card className="main-header-bg">
              <Card.Body>
                <h3 className="text-white">
                  Privacy Policy for Smarter Payout
                </h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="mx-lg-5 mx-sm-1">
          <Row>
            <Col>
              <p className="letter-sp-text">
                At Smart Settlement Solutions ("we," "us," or "our"), we value
                your privacy and are committed to safeguarding the personal
                information you entrust to us. This Privacy Policy outlines how
                we collect, use, share, and protect your information when you
                use our services, whether through our website, mobile
                application, or other platforms.
              </p>
              <p>
                <br />
                <b>
                  {" "}
                  No mobile information will be shared with third
                  parties/affiliates for marketing/promotional purposes. All the
                  below categories exclude text messaging originator opt-in data
                  and consent; this information will not be shared with any
                  third parties.
                </b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Information We Collect</h5>
              <p className="letter-sp-text">
                We may collect various types of information, including but not
                limited to: Personal Information: This may include your name,
                email address, phone number, and other contact information.
                Usage Information: We gather data about how you use our
                services, including your interactions with our website,
                applications, and other online platforms. Payment Information:
                When necessary, we may collect payment details such as credit
                card information. Device Information: We may collect information
                about the device you use to access our services, such as your IP
                address, browser type, and operating system.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> How We Use Your Information</h5>
              <p className="letter-sp-text">
                We use the information we collect for various purposes,
                including: Providing and improving our services. Responding to
                your inquiries and requests. Personalizing your experience.
                Sending you updates, promotions, and other communications.
                Complying with legal requirements. Detecting and preventing
                fraud and other misuse of our services.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Opting Out of Text Messages</h5>
              <p className="letter-sp-text">
                By providing your phone number to Smart Settlement Solutions,
                you agree and acknowledge that we may send text messages to your
                wireless phone number for any purpose. Message and data rates
                may apply. We will only send one SMS as a reply to you, and you
                will be able to Opt-out by replying "STOP."
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Information Sharing</h5>
              <p className="letter-sp-text">
                We will not share your information with third parties without
                your consent, except for the following circumstances: With your
                consent. To service providers, business partners, or contractors
                who help us operate and improve our services. In response to
                legal requests or court orders. To protect our rights, privacy,
                safety, or property. In connection with a business transaction,
                such as a merger, acquisition, or sale of assets.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text">Data Security</h5>
              <p className="letter-sp-text">
                We take the security of your information seriously and employ
                appropriate technical, administrative, and physical measures to
                safeguard it. However, please be aware that no data transmission
                or storage is entirely secure, and we cannot guarantee the
                security of your information.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5 className="letter-sp-text"> Changes to Privacy Policy</h5>
              <p className="letter-sp-text">
                We may update this Privacy Policy from time to time. If we make
                any changes, we will post the updated policy on our website, and
                the date of the latest revision will be provided.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="letter-sp-text"> Contact Us</h5>
              <p className="letter-sp-text">
                If you have any questions or concerns about this Privacy Policy,
                how your data is handled, or wish to exercise your privacy
                rights, please contact us at info@smarterpayouts.com. Thank you
                for choosing Smart Settlement Solutions. Your trust and privacy
                are of utmost importance to us.
              </p>
            </Col>
          </Row>
        </div>
        <GetInTouch />
      </Container>
    </div>
  );
};
