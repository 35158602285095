import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./ClientReviews.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ClientReviews.css";
import StarRatings from "react-star-ratings";
import manavatar from "../assets/img/avatars/man.png";
import ladyavatar from "../assets/img/avatars/lady.png";
import familyavatar from "../assets/img/avatars/family.png";
import manavatar2 from "../assets/img/avatars/man2.jpeg";
import ladyavatar2 from "../assets/img/avatars/lady2.jpeg";

/**
 * @author
 * @function ClientReviews
 **/

export const ClientReviews = (props) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		speed: 3000,
		autoplaySpeed: 2000,
		cssEase: "linear",
	};

	return (
		<Container>
			<Row>
				<div className='text-center mb-3'>
					<h1 className='tag-color-black'>Testimonials </h1>
					<p
						style={{
							textAlign: "center",
							letterSpacing: "1px",
							fontSize: "18px",
							fontWeight: "500",
						}}
						className='tag-color-client'>
						What our clients say about us
					</p>
				</div>
			</Row>
			<Row>
				<Col>
					<div className='text-center'>
						<Slider {...settings}>
							<div className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
								<Row className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
									<Col lg={4} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-profilfe '>
											<Card.Body>
												<div>
													<img
														className='review-client shadow'
														src={manavatar}></img>
													<h5 className='client-name'>R. Campos</h5>
													{/* <h5 className='client-des  invisible'>
														CEO, RHYTHEM
													</h5> */}
												</div>
											</Card.Body>
										</Card>
									</Col>
									<Col lg={8} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-layout '>
											<Card.Body>
												<StarRatings
													rating={5}
													starRatedColor='gold'
													//changeRating={this.changeRating}
													numberOfStars={5}
													name='rating'
													starDimension='20px'
												/>
												<Card.Text className='cleint-review-text'>
													It was great to be able to see my payment pricing
													without having to call someone first.
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</div>
							<div className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
								<Row className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
									<Col lg={4} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-profilfe '>
											<Card.Body>
												<div>
													<img
														className='review-client shadow'
														src={familyavatar}></img>
													<h5 className='client-name'>R. Quiles</h5>
													{/* <h5 className='client-des  invisible'>
														CEO, RHYTHEM
													</h5> */}
												</div>
											</Card.Body>
										</Card>
									</Col>
									<Col lg={8} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-layout '>
											<Card.Body>
												<StarRatings
													rating={5}
													starRatedColor='gold'
													//changeRating={this.changeRating}
													numberOfStars={5}
													name='rating'
													starDimension='20px'
												/>
												<Card.Text className='cleint-review-text'>
													Upfront offer along with a family protection benefit
													to protect my own. Best decision I ever made. You must
													see it to believe it.
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</div>
							<div className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
								<Row className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
									<Col lg={4} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-profilfe '>
											<Card.Body>
												<div>
													<img
														className='review-client shadow'
														src={ladyavatar}></img>
													<h5 className='client-name'>M. JOLET</h5>
													{/* <h5 className='client-des  invisible'>
														CEO, RHYTHEM
													</h5> */}
												</div>
											</Card.Body>
										</Card>
									</Col>
									<Col lg={8} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-layout '>
											<Card.Body>
												<StarRatings
													rating={5}
													starRatedColor='gold'
													//changeRating={this.changeRating}
													numberOfStars={5}
													name='rating'
													starDimension='20px'
												/>
												<Card.Text className='cleint-review-text'>
													Got my upfront offer in around 2 minutes. Smarter
													Payouts finally makes this long process transparent
													and easy.
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</div>
							<div className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
								<Row className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
									<Col lg={4} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-profilfe '>
											<Card.Body>
												<div>
													<img
														className='review-client shadow'
														src={manavatar2}></img>
													<h5 className='client-name'>N. Davis</h5>
													{/* <h5 className='client-des  invisible'>
														CEO, RHYTHEM
													</h5> */}
												</div>
											</Card.Body>
										</Card>
									</Col>
									<Col lg={8} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-layout '>
											<Card.Body>
												<StarRatings
													rating={5}
													starRatedColor='gold'
													//changeRating={this.changeRating}
													numberOfStars={5}
													name='rating'
													starDimension='20px'
												/>
												<Card.Text className='cleint-review-text'>
													Coming from a previous bad experience, It was a relief
													to see the true value of my payments right away. No
													guessing !
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</div>
							<div className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
								<Row className='px-lg-5 px-md-2 px-sm-2 px-xs-2'>
									<Col lg={4} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-profilfe '>
											<Card.Body>
												<div>
													<img
														className='review-client shadow'
														src={ladyavatar2}></img>
													<h5 className='client-name'>C. Mead</h5>
													{/* <h5 className='client-des  invisible'>
														CEO, RHYTHEM
													</h5> */}
												</div>
											</Card.Body>
										</Card>
									</Col>
									<Col lg={8} md={12} className='mb-3'>
										<Card className='h-100 border-0 review-grid-layout '>
											<Card.Body>
												<StarRatings
													rating={5}
													starRatedColor='gold'
													//changeRating={this.changeRating}
													numberOfStars={5}
													name='rating'
													starDimension='20px'
												/>
												<Card.Text className='cleint-review-text'>
													I was very impressed how accurate my final payout was
													in regard to the initial offer.
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</div>
						</Slider>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
