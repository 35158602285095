import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./SessionExpired.css";

/**
 * @author
 * @function SessionExpired
 **/

export const SessionExpired = (props) => {
	return (
		<Modal
			fullscreen={true}
			{...props}
			size='md'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Body className='text-center session-form-layout '>
				<h4>
					{" "}
					<img src={require("../../assets/img/sessionExpired.png")} />
				</h4>
				<h3>Session Expired</h3>
				<p className='px-2'>
					Your Session has been expired or you are not authorized to this link.
					Session is completely Private.
				</p>
				<Button className='redirect-button' onClick={() => props.redirector()}>
					Redirect Now
				</Button>
			</Modal.Body>
		</Modal>
	);
};
