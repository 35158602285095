export const mapper = {
  Age: "",
  BloodPressure: "",
  Email: "user@smarterPayouts.com",
  FirstName: "Congratulation",
  Gender: "Male",
  Health: "",
  Height: 0,
  InsuranceCompany: "Others",
  LastName: "ads",
  LicenseSuspended: "",
  MenuallWeight: 0,
  Menuallage: 0,
  PaymentAmount: 3123,
  PaymentEndDate: "2024-01-15",
  PaymentMode: "Quarterly",
  PaymentStartDate: "2023-05-15",
  PercentStep: 0,
  PhoneNumber: "",
  ProductType: "2",
  Smoker: "",
  Weight: "Average Weight",
};
