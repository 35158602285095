import React from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import "./GobackPopup.css";
import { useLocation, useNavigate, useHistory } from "react-router-dom";

/**
 * @author
 * @function GobackPopup
 **/

export const GobackPopup = (props) => {
	const navigate = useNavigate();

	const discardOffer = () => {
		navigate("/");
	};
	return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center goback-form-layout">
        <h4>
          {" "}
          <img
            height={100}
            weight={100}
            src={require("../../../assets/img/gobackwait.png")}
          />
        </h4>
        <h4 className="my-4">Can't Plan it?</h4>
        <p className="my-4 text-center">
          If you have any questions, feel free to call us. Don't worry if you're
          unsure about your exact payment amount or due date, we can still
          assist you.
        </p>

        {/* <Alert
					style={{ fontSize: "13px" }}
					className='shadow mb-5'
					key='danger'
					variant='danger'>
					If you go back, your information and quote will be lost.
				</Alert> */}
        <Button className="getqoutes-button mb-1" onClick={discardOffer}>
          Go Back
        </Button>
        <Button className="goback-button mb-1" onClick={() => props.getquote()}>
          Get Offer
        </Button>
        <h6 className="text-muted fw-bolder mt-5">Call Us</h6>
        <p style={{ fontSize: "18px" }} className="fw-bolder mt-3 mb-2">
          <a
            style={{
              color: "#54d000",
              textDecoration: "none",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
            href="tel:+18669729688"
          >
            +1 (561) -583-1280
          </a>
        </p>
      </Modal.Body>
    </Modal>
  );
};
