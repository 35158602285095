import { initializeApp } from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
var config = {
	apiKey: "AIzaSyDbjxwYZAtrBa3VI8ftKk4RAjFEijr-3NI",
	authDomain: "smarter-payouts-opt.firebaseapp.com",
	projectId: "smarter-payouts-opt",
	storageBucket: "smarter-payouts-opt.appspot.com",
	messagingSenderId: "305282266453",
	appId: "1:305282266453:web:240ddc910ee09f28fbebdc",
};
const firebaseApp = initializeApp(config);

export default firebaseApp;
