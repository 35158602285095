import { useFormik } from "formik";
import React, { useContext, useState, useEffect } from "react";

import { FormContext } from "../CalculatorForm";
import { Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import {
  AGE_MALE_Female,
  ANNUAL_INCREASE,
  Payment_Mode,
} from "../FormConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../CalculatorForm.css";
import { schema } from "./StepOneSchema";
import { initialValues } from "./StepOneValues";
import "./StepOne.css";
import { useNavigate } from "react-router-dom";
import { mapper } from "../CalculationLayout/CalculationConstants";
import { addYears } from "date-fns";
import { getDynamicYears } from "../../../../constant/utils/Utils";
/**
 * @author
 * @function StepOne
 **/
const start_end_min = new Date();
export const StepOne = (props) => {
  const [maxSetter, setmaxSetter] = useState(addYears(new Date(), 65));
  const [LCPlist, setLCPlist] = useState([]);
  const [ageDsiabler, setageDsiabler] = useState(true);
  const [endDatemin, setendDatemin] = useState(
    new Date(start_end_min.setMonth(start_end_min.getMonth() + 15))
  );

  const navigate = useNavigate();
  const {
    active,
    setActive,
    formData,
    setFormData,
    LCPForm,
    setLCPForm,
    gotoNext,
    gotoBack,
    submit,
    goToSlide,
  } = useContext(FormContext);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setTouched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, action) => {
      console.log(values);
      const data = { ...formData, ...values };
      setFormData(data);
      if (LCPForm) {
        mapper.Age = +data.Age;
        mapper.FirstName = "Congratulation";
        mapper.PaymentAmount = +data.PaymentAmount;
        mapper.PaymentEndDate = data.PaymentEndDate;
        mapper.PaymentStartDate = data.PaymentStartDate;
        mapper.ProductType = data.PaymentType;
        mapper.PaymentMode = data.PaymentMode;
        mapper.PercentStep = +data.AnnualIncrease;
        navigate(`/results/userRequest=Congtratulation`, {
          state: mapper,
        });
        submit(data);
      } else {
        gotoNext();
        setActive(2);
      }
    },
  });

  const paymentCustomFunction = (value) => {
    setFieldValue("PaymentType", value);
    if (value == 2) {
      setLCPForm(true);
      setmaxSetter(addYears(new Date(), 65));
    } else {
      setLCPForm(false);
    }

    console.log("------", value);
  };
  const paymentModeCustomFunction = (value) => {
    if (value === "Lumpsum") {
      goToSlide(2);
    } else {
      setFieldValue("PaymentMode", value);
    }
  };

  const Agfunction = async (value) => {
    setageDsiabler(false);
    setFieldValue("Age", value);
    console.log(value);
    let getList = await getDynamicYears(value);
    console.log("==", getList);
    //setFieldValue("PaymentEndDate", getList[0]);
    setLCPlist(getList);
  };
  const handleStartDate = (date) => {
    let selectedDate = new Date(date);
    selectedDate.setFullYear(selectedDate.getFullYear() + 1);
    setendDatemin(selectedDate);

    setFieldValue("PaymentStartDate", date);
  };
  console.log(errors);

  return (
    <Form
      autoComplete="off"
      className=" form-one-Style px-1  mb-2"
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Row className="my-2 mb-1 justify-content-center">
        <Form.Group
          as={Col}
          md="12"
          lg="6"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Payment Mode
          </Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              size="md"
              className="form-select"
              value={values.PaymentMode}
              name="PaymentMode"
              isInvalid={touched.PaymentMode && !!errors.PaymentMode}
              onChange={(e) => {
                paymentModeCustomFunction(e.target.value);
              }}
              onBlur={() => {
                setTouched({ PaymentMode: false });
              }}
            >
              <option value="" selected disabled>
                Select....
              </option>
              {Payment_Mode.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </Form.Select>
          </InputGroup>
          {errors.PaymentMode && touched.PaymentMode ? (
            <span className="error-invlaid ">{errors.PaymentMode}</span>
          ) : null}
        </Form.Group>
        <Form.Group as={Col} md="12" lg="6" controlId="validationFormik02">
          <Form.Label className="custom-label fw-bolder ">
            Payment Type
          </Form.Label>

          <InputGroup>
            <Form.Select
              size="md"
              className="form-select"
              value={values.PaymentType}
              name="PaymentType"
              isInvalid={touched.PaymentType && !!errors.PaymentType}
              onChange={(e) => {
                paymentCustomFunction(e.target.value);
              }}
              onBlur={() => {
                setTouched({ PaymentType: true });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              <option value="1">Life Payment (LCP)</option>
              <option value="2">Guranteed Payments (GP)</option>
              <option value="0">I Don’t Know</option>
            </Form.Select>
          </InputGroup>
          {errors.PaymentType && touched.PaymentType ? (
            <span className="error-invlaid ">{errors.PaymentType}</span>
          ) : null}
        </Form.Group>

        <Form.Group
          as={Col}
          md="12"
          lg="6"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">Age</Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              size="md"
              className="form-select"
              value={values.Age}
              name="Age"
              isInvalid={touched.Age && !!errors.Age}
              onChange={(e) => {
                Agfunction(e.target.value);
              }}
              onBlur={() => {
                setTouched({ Age: false });
              }}
            >
              {AGE_MALE_Female.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
              <option value="" selected disabled hidden>
                Select....
              </option>
            </Form.Select>
          </InputGroup>
          {errors.Age && touched.Age ? (
            <span className="error-invlaid ">{errors.Age}</span>
          ) : null}
        </Form.Group>

        <Form.Group
          as={Col}
          md="12"
          lg="6"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Payment Amount
          </Form.Label>
          <InputGroup hasValidation>
            <CurrencyInput
              className="form-control"
              id="PaymentAmount"
              name="PaymentAmount"
              placeholder="$500.00"
              defaultValue={1000}
              decimalsLimit={2}
              prefix=" $"
              allowNegativeValue={false}
              onValueChange={(value, name) => {
                setFieldValue("PaymentAmount", value);
              }}
              value={values.PaymentAmount}
              onBlur={handleBlur}
              isInvalid={touched.expirationDate && errors.expirationDate}
            />
          </InputGroup>
          {errors.PaymentAmount && touched.PaymentAmount ? (
            <span className="error-invlaid ">{errors.PaymentAmount}</span>
          ) : null}
        </Form.Group>

        <Form.Group
          as={Col}
          md="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder">
            Annual Increase
          </Form.Label>

          <InputGroup hasValidation>
            <Form.Select
              className="form-control"
              value={values.AnnualIncrease}
              name="AnnualIncrease"
              isInvalid={touched.AnnualIncrease && !!errors.AnnualIncrease}
              onChange={handleChange}
              onBlur={() => {
                setTouched({ AnnualIncrease: false });
              }}
            >
              <option value="" selected disabled hidden>
                Select....
              </option>

              {ANNUAL_INCREASE.map((item, i) => {
                return (
                  <option key={i} value={item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="12"
          sm="12"
          lg="4"
          controlId="validationFormikUsername"
        >
          <Form.Label className="custom-label fw-bolder ">
            Start Date
          </Form.Label>
          <InputGroup className="" hasValidation>
            <DatePicker
              showMonthYearPicker
              dateFormat="MM/yyyy"
              portalId="root-portal"
              className="form-control w-100"
              name="PaymentStartDate"
              onChange={(date) => handleStartDate(date)}
              selected={values.PaymentStartDate}
              isInvalid={touched.PaymentStartDate && !!errors.PaymentStartDate}
            />
          </InputGroup>
          {errors.PaymentStartDate && touched.PaymentStartDate ? (
            <span className="error-invlaid ">{errors.PaymentStartDate}</span>
          ) : null}
        </Form.Group>
        {LCPForm ? (
          <Form.Group
            as={Col}
            md="12"
            lg="4"
            controlId="validationFormikUsername"
          >
            <Form.Label className="custom-label fw-bolder">End Date</Form.Label>
            <InputGroup hasValidation>
              <DatePicker
                minDate={endDatemin}
                maxDate={maxSetter}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                portalId="root-portal"
                className="form-control w-100"
                name="PaymentEndDate"
                onChange={(date) => setFieldValue("PaymentEndDate", date)}
                selected={values.PaymentEndDate}
                isInvalid={touched.PaymentEndDate && !!errors.PaymentEndDate}
                // onKeyDown={(e) => {
                // 	e.preventDefault();
                // }}
                disabled={ageDsiabler}
              />
            </InputGroup>
            {errors.PaymentEndDate && touched.PaymentEndDate ? (
              <span className="error-invlaid ">{errors.PaymentEndDate}</span>
            ) : null}
          </Form.Group>
        ) : (
          <Form.Group
            as={Col}
            sm="12"
            md="12"
            lg="4"
            controlId="validationFormikUsername"
          >
            <Form.Label className="custom-label fw-bolder">End Date</Form.Label>
            <InputGroup>
              <InputGroup>
                <Form.Select
                  className="form-control"
                  value={values.PaymentEndDate}
                  name="PaymentEndDate"
                  //isInvalid={touched.PaymentEndDate && !!errors.PaymentEndDate}
                  onChange={handleChange}
                  onBlur={() => {
                    setTouched({ PaymentEndDate: false });
                  }}
                >
                  {LCPlist.map((item, i) => {
                    return (
                      <option key={i} value={item.item}>
                        {item.label}
                      </option>
                    );
                  })}
                  <option value="" selected disabled hidden>
                    Select....
                  </option>
                </Form.Select>
              </InputGroup>
            </InputGroup>
          </Form.Group>
        )}
      </Row>
      <div className="text-center  mt-5">
        <Button className=" mx-2 submt-Button" type="submit">
          {LCPForm ? "CALCULATE" : "NEXT"}
        </Button>
      </div>
    </Form>
  );
};
